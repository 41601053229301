<template>
  <div>
    <ApplicationNannyListItem
      v-if="hasPremiumNanny"
      :item="response.from_application_nanny_id_populated"
      :is-wishlistable="true"
      :is-geo="true"
      :is-views-amount="false"
      :response="response"
    />
    <ApplicationNannyResponseOnOfferPaywall v-else :response="response" />
  </div>
</template>

<script>
import ApplicationNannyListItem from "@/components/Application/Nanny/ApplicationNannyListItem.vue";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import ApplicationNannyResponseOnOfferPaywall from "./ApplicationNannyResponseOnOfferPaywall.vue";

export default {
  components: {
    ApplicationNannyListItem,
    ApplicationNannyResponseOnOfferPaywall,
  },

  props: {
    response: Object,
  },

  setup() {
    const userStore = useUserStore();
    const { hasPremiumNanny } = storeToRefs(userStore);

    return {
      hasPremiumNanny,
    };
  },
};
</script>
