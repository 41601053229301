<template>
  <div class="section-list-items">
    <template v-for="(response, index) in responses" :key="response.id">
      <OfferNannyListItem
        v-if="userType === 'contractor'"
        @click="handleClick(response, 'response-from-me-on-offer')"
        :is-geo="true"
        :isDateAdded="true"
        :item="response.on_offer_nanny_id_populated"
      />
      <ApplicationNannyResponseOnOffer
        v-if="userType === 'client'"
        @click="handleClick(response, 'response-to-my-offer')"
        :response="response"
      />
      <div
        v-if="index === responses.length - 5"
        v-intersection
        @intersection="$event.detail.isOnScreen ? $emit('fetchMore') : ''"
        class="fetch-more-element section-list-items__fetch-more-element"
      ></div>
    </template>
  </div>
</template>

<script>
import { watch } from "vue";

import intersectionDirective from "@/directives/intersection";

import OfferNannyListItem from "@/components/Offer/Nanny/OfferNannyListItem.vue";
import { useUserStore } from "@/stores/user";
import ApplicationNannyResponseOnOffer from "@/components/List/ListResponseItem/ApplicationNannyResponseOnOffer.vue";
import { storeToRefs } from "pinia";

export default {
  components: { ApplicationNannyResponseOnOffer, OfferNannyListItem },

  directives: {
    intersection: intersectionDirective,
  },

  props: {
    responses: Array,
  },

  emits: ["fetchMore", "responseClick"],

  setup(props, { emit }) {
    const userStore = useUserStore();
    const { userType } = storeToRefs(userStore);

    watch(
      () => props.responses,
      (responses) => {
        if (responses.length === 0) {
          emit("fetchMore");
        }
      },
      { immediate: true }
    );

    const handleClick = (response, type) => {
      emit("responseClick", { response, type });
    };

    return {
      handleClick,
      userType,
    };
  },
};
</script>

<style scoped>
.section-list-items {
  width: 100%;
  display: flex;
  flex-flow: column;
  row-gap: 60px;
}
.section-list-items__fetch-more-element {
  margin: -30px 0;
}
</style>
