<template>
  <div class="paywall-container">
    <component
      @click="handlePushToPremium()"
      :is="Component"
      :response="response"
    />
    <ButtonCta
      :to="{ name: ROUTE_ACCOUNT_PREMIUM_NAME }"
      :label="'Odblokuj podgląd'"
      :size="'small'"
      class="paywall-container__cta-button"
    />
  </div>
</template>

<script>
import { computed, markRaw } from "@vue/reactivity";
import useIsMobile from "@/composables/useIsMobile";
import { ROUTE_ACCOUNT_PREMIUM_NAME } from "@/router/constants";
import { ROUTE_PUSH_TO_PREMIUM_NAME } from "@/router/constants";

import ApplicationNannyResponseOnOfferPaywallDesktop from "@/components/List/ListResponseItem/ApplicationNannyResponseOnOfferPaywallDesktop.vue";
import ApplicationNannyResponseOnOfferPaywallMobile from "@/components/List/ListResponseItem/ApplicationNannyResponseOnOfferPaywallMobile.vue";
import ButtonCta from "@/components/UI/Base/ButtonCta.vue";
import { useRouter } from "vue-router";

export default {
  components: { ButtonCta },
  props: {
    response: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const { isMobile } = useIsMobile();

    const Component = computed(() => {
      return isMobile.value
        ? markRaw(ApplicationNannyResponseOnOfferPaywallMobile)
        : markRaw(ApplicationNannyResponseOnOfferPaywallDesktop);
    });

    const router = useRouter();

    const handlePushToPremium = () => {
      router.push({
        name: ROUTE_PUSH_TO_PREMIUM_NAME,
      });
    };

    return {
      Component,
      ROUTE_ACCOUNT_PREMIUM_NAME,
      handlePushToPremium,
    };
  },
};
</script>

<style scoped>
.paywall-container {
  display: flex;
  flex-flow: column;
  row-gap: 40px;
}
.paywall-container__cta-button {
  margin-right: auto;
}

@media (max-width: 1200px) {
  .paywall-container__cta-button {
    margin: auto;
  }
}
</style>
