<template>
  <div class="content">
    <BaseH1 :html="'Zgłoszenia'" :short="true" class="h1 content__h1" />
    <ListResponses
      v-if="currentResponses_TEMPORARY_FIX.length > 0"
      :responses="currentResponses_TEMPORARY_FIX"
      @responseClick="handleResponseClick"
    />
    <SvgIllustrationsEmptySpace
      v-if="currentResponses_TEMPORARY_FIX.length === 0"
    />

    <NavMobile v-if="isMobile" />
  </div>
</template>

<script>
import useAccountResponses from "@/composables/useAccountResponses";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import SvgIllustrationsEmptySpace from "@/components/Svg/Illustrations/SvgIllustrationsEmptySpace.vue";
import { computed } from "vue";
import NavMobile from "@/components/NavMobile.vue";
import useIsMobile from "@/composables/useIsMobile";
import offerNannyResponsesService from "@/services/offerNannyResponses";
import { useUserStore } from "@/stores/user";
import ListResponses from "@/components/List/ListResponses.vue";

export default {
  components: {
    BaseH1,
    SvgIllustrationsEmptySpace,
    NavMobile,
    ListResponses,
  },

  setup() {
    const { currentResponses } = useAccountResponses();
    const { isMobile } = useIsMobile();

    /**
     * TEMPORARY_FIX: while we don't have both incoming and outgoing responses
     */
    const currentResponses_TEMPORARY_FIX = computed(() => {
      return currentResponses.value.value.flatMap(({ responses }) => responses);
    });

    const userStore = useUserStore();

    const handleResponseClick = async (response, type) => {
      if (type === "response-to-my-offer") {
        handleResponseToMyOffer(response);
      }
    };

    const handleResponseToMyOffer = async (response) => {
      try {
        // TODO: check if user is Premium!
        await markOfferResponseAsSeen(response._id);
        await userStore.refetchUserStore();
      } catch (error) {
        alert(error.message);
      }
    };

    const markOfferResponseAsSeen = async (responseId) => {
      return await offerNannyResponsesService.updateOne(responseId, {
        isSeen: true,
      });
    };

    return {
      currentResponses_TEMPORARY_FIX,
      isMobile,
      handleResponseClick,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;

  padding: 60px 0;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 60px;
}

@media (max-width: 1200px) {
  .content {
    padding: 30px 0px 80px;
    display: flex;
    flex-flow: column;
    row-gap: 30px;
  }
}
</style>
