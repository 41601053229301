import { useUserResponsesStore } from "@/stores/userResponses";
import { storeToRefs } from "pinia";

import { onMounted } from "vue";

export default function useAccountResponses() {
  const responses = useUserResponsesStore();
  const { currentResponses } = storeToRefs(responses);

  const initResponses = async () => {
    try {
      console.log("INIT RESPONSES");
      await responses.initAccountResponsesView();
    } catch (error) {
      console.log(error);
    }
  };

  onMounted(() => {
    initResponses();
  });

  // TODO: make responses view dynamic
  // watch(typeAds, () => initResponses());

  return { currentResponses };
}
